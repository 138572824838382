.datatable {
    height: 600px;
    padding: 15px;
    
    .datatableTitle {
        width: 100%;
        font-size: 24px;
        color: gray;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        .link {
            text-decoration: none;
            color: green;
            font-size: 16px;
            font-weight: 400;
            border: 1px solid green;
            padding: 5px;
            border-radius: 5px;
            cursor: pointer;
        }
    }
    
    .cellWithImg {
        display: flex;
        align-items: center;
        
        .cellImg {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            object-fit: cover;
            margin-right: 20px;
        }
    }
    
    .cellWithStatus {
        padding: 5px;
        border-radius: 5px;
        width: 100%;
        max-width: 100px;
        text-align: center;
        font-size: 13px;
        
        &.active {
            background-color: rgba(0, 128, 0, 0.05);
            color: green;
            border: 1px solid rgba(0, 255, 0, 0.2);
        }
        
        &.pending {
            background-color: rgba(255, 217, 0, 0.05);
            color: goldenrod;
            border: 1px solid rgba(218, 165, 32, 0.2);
        }
        
        &.passive {
            background-color: rgba(255, 0, 0, 0.05);
            color: crimson;
            border: 1px solid rgba(220, 20, 60, 0.1);
        }
    }
    
    .cellAction {
        display: flex;
        align-items: center;
        gap: 15px;
        
        .viewButton {
            padding: 2px 5px;
            border-radius: 5px;
            color: darkblue;
            border: 1px dotted rgba(0, 0, 139, 0.596);
            cursor: pointer;
            
            &:hover {
                background-color: rgba(0, 0, 139, 0.09);
            }
        }
        
        .deleteButton {
            padding: 2px 5px;
            border-radius: 5px;
            color: crimson;
            border: 1px dotted rgba(220, 20, 60, 0.6);
            cursor: pointer;
            
            &:hover {
                background-color: rgba(220, 20, 60, 0.09);
            }
        }
    }
}