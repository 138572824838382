.sidebar {

    $breakpoint-tablet: 768px;
    
    .listTitle {
        display: none;
    }

    .title {
        display: none;
    }

    max-width: 190px;
    width: auto;
    border-right: 0.5px solid rgba(230, 227, 227, 1);
    min-height: 100vh;

    .top {
        background-color: transparent;
        color: black;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 0.5px solid rgb(230, 227, 227);

        .logo {
            font-size: 20px;
            font-weight: 600;
            color: #6439ff;
        }

        .logoDesktop {
            display: none;
        }

        @media (min-width: $breakpoint-tablet) {
            .logoDesktop {
                display: inline-block;
            }
        }

    }

    hr {
        height: 0;
        border: 0.5px solid rgb(230, 227, 227);
        margin: 0;
    }

    .center {
        padding-left: 10px;

        ul {
            padding: 0;
            margin: 0;
            list-style: none;

            .title {
                font-size: 10px;
                font-weight: bold;
                color: #999;
                margin-top: 15px;
                margin-bottom: 5px;
            }

            li {
                display: flex;
                align-items: center;
                padding: 5px;
                cursor: pointer;

                &:hover {
                    background-color: #ece8ff
                }

                .icon {
                    font-size: 18px;
                    color: #7451f8;
                }

                span {
                    font-size: 13;
                    font-weight: 600;
                    color: #888;
                    margin-left: 10px;
                }
            }
        }
    }

    .bottom {
        display: flex;
        align-items: start;
        margin: 10px;
        flex-direction: column;

        .colorOption {
            width: 20px;
            height: 20px;
            border-radius: 5px;
            border: 1px solid #7451f8;
            cursor: pointer;
            margin: 5px;

            &:nth-child(1){
                background-color: whitesmoke;
            }
            &:nth-child(2){
                background-color: black ;
            }
        }

        .title {
            font-size: 10px;
            font-weight: bold;
            color: #999;
            margin-top: 15px;
            margin-bottom: 5px;
        }

        .modes {
            display: block;
            align-items: center;
            justify-content: space-between;
        }
        
        @media (min-width: $breakpoint-tablet) {
            .modes {
                display: flex;
            }
        }

    }

    $breakpoint-tablet: 768px;
    @media (min-width: $breakpoint-tablet) {

        .listTitle {
            display: block;
        }

        .title {
            display: block;
        }
    }

}